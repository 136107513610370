<template>
  <div class="popup-container" :class="{'is-active': modelValue}" id="kb-eTest">
    <div class="popup eTest-modal-container">
      <EvlMain v-if="isStart"
               :evl="evl"
               :is-end="isEnd"
               :is-mock="isMock"
               :init-dt="initDt"
               :can-take="canTake"
               :current-time="currentTime"
               :current-time-text="currentTimeText"
               @closeEvl="closeModal"
               @endEvl="endEvaluation"
      />
      <EvlIntro v-else
                :evl="evl"
                :is-ready="isReady"
                :is-end="isEnd"
                :can-take="canTake"
                :current-time-text="currentTimeText"
                @closeEvl="closeModal"
                @readyEvl="readyEvaluation"
                @initEvl="initEvaluation"
                @initMockEvl="initMockEvaluation"
      />
    </div>


    <div v-if="scoreToggle" class="kb-alert-container is-active" id="alert-score">
      <div class="kb-alert">
        <div class="kb-alert-contents">
          <strong class="kb-alert-title">평가종료</strong>
          <p class="kb-alert-score">{{ score }}점</p>
          <p class="kb-alert-text">종료시간 이전에 비정상 종료된 경우 F5키를 눌러 <br>다시 참여하실 수 있습니다.</p>
        </div>
        <div class="kb-alert-actions">
          <button class="kb-btn-alert" @click="scoreToggle = false"><span class="text">확인</span></button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


import EvlMain from '@/components/evaluation/EvlMain';
import EvlIntro from '@/components/evaluation/EvlIntro';
import {computed, onUnmounted, ref} from 'vue';
import {
  ACT_GET_LRN_TY_EVL_RSLT_LIST,
  // ACT_GET_LRN_TY_EVL_RSLT_SCORE,
  ACT_INIT_LRN_TY_EVL_DIST,
} from '@/store/modules/evaluation/evaluation';
import {clearTargetInterval, getResult, isSuccess, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: 'EvlModal',
  components: {EvlIntro, EvlMain},
  props: {
    modelValue: Boolean,
    evl: Object
  },
  emits: ['update:modelValue', 'renewInit', 'renewEnd'],
  setup(props, {emit}){
    const store = useStore();
    const {showMessage} = useAlert();
    // 평가 준비 여부!

    const isMock = ref(false);
    const isReady = ref(false);
    const isStart = ref(false);
    const isEnd = ref(false);
    const score = ref(0);
    const scoreToggle = ref(false);

    const currentTime = ref(new Date());
    const currentTimeText = computed(() => timestampToDateFormat(currentTime.value, 'yyyy.MM.dd hh:mm'));

    const countInterval = ref(null);
    countInterval.value = setInterval(() => {
      currentTime.value = new Date();
    }, 1000);

    const checkCanTake = () => {
      if(props.evl && props.evl.lrnTyEvlDistSn > 0){
        // 시험을 수행하지 않는 경우
        if(props.evl.evlExamEndDt !== null){
          return 1;
        }else{
          if(props.evl.evlExamBgngDt !== null){
            if(props.evl.evlExamBgngDt + (1000 * 60 * props.evl.evlReqHr) >= currentTime.value.getTime()){
              return 0;
            }
            // 시험 종료
            else{
              return 1;
            }
          }
          // 시험 시작하지 않음
          else{
            // 시험기간만 확인
            if(currentTime.value.getTime() >= props.evl.evlBgngDt && currentTime.value.getTime() < props.evl.evlEndDt){
              return 0;
            }
            // 시험 종료
            else if(currentTime.value.getTime() > props.evl.evlEndDt){
              return 1;
            }
          }
        }
      }
      return 2;
    }
    const canTake = computed(checkCanTake);

    const initDt = computed(() => {
      if(isMock.value) return new Date().getTime();
      return props.evl.evlExamBgngDt;
    });

    const getEvaluationResultList = () => {
      store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_RSLT_LIST}`, props.evl.lrnTyEvlDistSn).then(res => {
        isReady.value = lengthCheck(res);
      }).catch(e => {
        console.error(e);
      });
    }

    const readyEvaluation = () => {
      isReady.value = true;
    }

    const initEvaluation = () => {
      store.dispatch(`evaluation/${ACT_INIT_LRN_TY_EVL_DIST}`, props.evl.lrnTyEvlDistSn).then(res => {
        if(isSuccess(res) && res.initDt){
          // 시험 시작 시간 갱신
          emit('renewInit', res.initDt);
          isStart.value = true;
        }else{
          showMessage(getResult(res).message);
        }

      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 다시 시도해주세요.');
      })
      isStart.value = true;
    }

    const initMockEvaluation = () => {
      isMock.value = true;
      isStart.value = true;
    }

    const endEvaluation = () => {
      if(isMock.value){
        isStart.value = false;
        isMock.value = false;
        showMessage('모의 시험이 종료되었습니다. 고생하셨습니다.');
      }else{

        isEnd.value = true;
        isStart.value = false;
        clearTargetInterval(countInterval.value);
        if(props.evl.evlScoreRlsYn === 'Y'){
          // store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_RSLT_SCORE}`,props.evl.lrnTyEvlDistSn).then(res => {
          //   if(res && res.score){
          //     score.value = res.score;
              scoreToggle.value = true;
          //   }
          // })
        }else{
          showMessage('시험이 종료되었습니다. 고생하셨습니다.');
        }
        emit('renewEnd');
      }
    }

    const closeModal = () => {
      emit('update:modelValue', false);
    }

    onUnmounted(() => {
      clearTargetInterval(countInterval.value);
    });

    getEvaluationResultList();


    return {
      isMock,
      isReady,
      isStart,
      isEnd,
      canTake,
      currentTime,
      currentTimeText,
      scoreToggle,
      score,
      initDt,
      initMockEvaluation,
      readyEvaluation,
      initEvaluation,
      endEvaluation,
      closeModal,

    }

  }
}
</script>